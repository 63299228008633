<script setup lang="ts">
definePageMeta({
  layout: 'splash',
})
const route = useRoute()
// parse error message
const errorMessage = route.query.error
  ? JSON.parse(decodeURI(route.query.error as string))
  : []
</script>

<template>
  <PageContent class="margin-auto">
    <PageHeading>Error's</PageHeading>
    <table
      class="col-span-full w-full table-auto border-collapse border border-gray-200"
    >
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-300 p-2 text-left">Field</th>
          <th class="border border-gray-300 p-2 text-left">Error Code</th>
          <th class="border border-gray-300 p-2 text-left">Expected</th>
          <th class="border border-gray-300 p-2 text-left">Received</th>
        </tr>
      </thead>
      <tbody v-if="errorMessage.length">
        <tr
          v-for="({ code, expected, received, path }, index) in errorMessage"
          :key="index"
        >
          <td class="border border-gray-300 p-2">
            {{ path[path.length - 1] }}
          </td>
          <td class="border border-gray-300 p-2">{{ code }}</td>
          <td class="border border-gray-300 p-2">{{ expected }}</td>
          <td class="border border-gray-300 p-2">{{ received }}</td>
        </tr>
      </tbody>
    </table>
  </PageContent>
</template>
